<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <polygon
      style="fill:#F64B4A;"
      points="425.198,221.451 425.198,512 86.802,512 86.802,229.292 259.921,56.173 "
    />
    <polygon
      style="fill:#82DCC7;"
      points="12.789,247.134 259.923,0 499.211,239.288 499.211,295.465 259.923,56.177 12.789,303.309 
	"
    />
    <polygon
      style="fill:#E84242;"
      points="425.203,221.451 425.203,267.683 259.925,102.405 86.807,275.524 86.807,229.292 
	259.925,56.173 "
    />
    <polygon
      style="fill:#FED159;"
      points="239.722,280.844 272.28,280.844 324.382,280.844 324.382,248.287 272.28,248.287 
	272.28,212.18 239.722,212.18 239.722,248.287 187.619,248.287 187.619,344.033 291.825,344.033 291.825,374.664 272.28,374.664 
	239.722,374.664 187.619,374.664 187.619,407.223 239.722,407.223 239.722,443.329 272.28,443.329 272.28,407.223 324.382,407.223 
	324.382,311.476 220.177,311.476 220.177,280.844 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
